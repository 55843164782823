.wrapper {
    position: relative !important;
    width: 100% !important;
    height: 0 !important;
}

.content {
    position:absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
}