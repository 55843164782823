table.data-grid {
    margin: auto;
    width: 100%;
}

span.data-grid-container, span.data-grid-container:focus {
    outline: none;
}

.data-grid-container .data-grid {
    table-layout: fixed;
    border-collapse: collapse;
}

.data-grid-container .data-grid .cell.updated {
    background-color: #18396326;
    transition : background-color 0ms ease ;
}
.data-grid-container .data-grid .cell {
    height: 12px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    cursor: cell;
    background-color: unset;
    transition : background-color 500ms ease-in;
    vertical-align: middle;
    text-align: right;
    border: 1px solid #e0e0e0;
    padding: 0;
    font-size: 10px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif
}
.data-grid-container .data-grid .cell.selected {
    border: 1px double #183963;
    transition: none;
    box-shadow: inset 0 -100px 0 #18396326;
}

.data-grid-container .data-grid .cell.read-only {
    background: #e0e0e0;
    color: #000;
    text-align: center;
    border: 1px solid #000;
}

.data-grid-container .data-grid .cell > .value-viewer {
    white-space: nowrap;
    text-overflow: clip;
    overflow:hidden;
    width:100%;
    display:block;
}


.data-grid-container .data-grid .cell > input {
    outline: none !important;
    border: none !important;
    text-align:right;
    width: 100%;
    height: 100%;
    background: none;
    display: block;
    font-size: 10px;
}


.data-grid-container .data-grid .cell {
    vertical-align: bottom;
}

.data-grid-container .data-grid .cell,
.data-grid-container .data-grid.wrap .cell,
.data-grid-container .data-grid.wrap .cell.wrap,
.data-grid-container .data-grid .cell.wrap,
.data-grid-container .data-grid.nowrap .cell.wrap,
.data-grid-container .data-grid.clip .cell.wrap {
    white-space: normal;
}

.data-grid-container .data-grid.nowrap .cell,
.data-grid-container .data-grid.nowrap .cell.nowrap,
.data-grid-container .data-grid .cell.nowrap,
.data-grid-container .data-grid.wrap .cell.nowrap,
.data-grid-container .data-grid.clip .cell.nowrap {
    white-space: nowrap;
    overflow-x: visible;
}

.data-grid-container .data-grid.clip .cell,
.data-grid-container .data-grid.clip .cell.clip,
.data-grid-container .data-grid .cell.clip,
.data-grid-container .data-grid.wrap .cell.clip,
.data-grid-container .data-grid.nowrap .cell.clip {
    white-space: nowrap;
    overflow-x: hidden;
}

.data-grid-container .data-grid .cell .value-viewer, .data-grid-container .data-grid .cell .data-editor {
    display: block;
}
